import React from "react";
import classNames from "classnames";
import { toCircle } from "flubber";
import { m } from "framer-motion";

const paths = {
  philanthropy: [
    "M287.787 6.77128L208.699 2.26179C139.398 -1.70884 75.5713 39.8125 51.1187 104.761L6.35506 223.624C2.12833 234.798 0.96525 246.909 2.9226 258.707C18.3261 351.251 87.0317 425.927 177.977 448.985L255.704 468.697C301.716 480.381 345.203 442.434 339.87 395.268C334.31 346.146 381.428 307.744 428.432 323.088L456.346 332.192C505.762 348.33 554.27 305.22 544.001 254.255L532.569 197.475C529.902 184.173 523.293 172.006 513.648 162.476L410.987 61.3673C377.91 28.8082 334.111 9.38054 287.759 6.71455L287.787 6.77128Z",
    "M285.489 19.5622L211.337 15.3079C146.376 11.5926 86.5211 50.533 63.5719 111.397L21.5599 222.858C17.6169 233.352 16.4822 244.725 18.326 255.757C32.765 342.516 97.1872 412.54 182.459 434.152L255.363 452.672C298.51 463.62 339.302 428.054 334.31 383.838C329.09 337.779 373.286 301.788 417.341 316.168L443.524 324.704C489.848 339.85 535.321 299.434 525.704 251.645L515.01 198.41C512.513 185.96 506.329 174.53 497.28 165.596L401.03 70.7832C370.024 40.2378 328.948 22.0296 285.489 19.5338V19.5622Z",
    "M283.221 32.3248C283.221 32.3248 214.005 28.3542 213.976 28.3542C153.327 24.8941 97.4434 61.2253 76.0544 118.034C76.0544 118.034 36.8225 222.064 36.8225 222.092C33.1347 231.877 32.0851 242.513 33.7871 252.836C47.2616 333.837 107.372 399.154 186.971 419.347C186.971 419.375 255.024 436.648 255.052 436.676C295.334 446.886 333.403 413.703 328.751 372.437C328.751 372.437 328.779 372.437 328.779 372.409C323.928 329.441 365.146 295.832 406.278 309.247L430.731 317.189C473.991 331.313 516.429 293.592 507.436 249.007C507.436 249.007 497.479 199.318 497.479 199.29C495.153 187.661 489.366 176.969 480.913 168.659L391.074 80.1709C362.139 51.6675 323.758 34.6505 283.221 32.3248V32.3248Z",
    "M280.922 45.088C280.922 45.088 216.613 41.401 216.585 41.401C160.276 38.1962 108.363 71.9181 88.5061 124.699C88.4778 124.699 52.0541 221.299 52.0258 221.355C48.5933 230.431 47.6005 240.358 49.2174 249.915C61.7274 325.13 117.554 385.796 191.451 404.543C191.451 404.6 254.654 420.652 254.71 420.681C292.098 430.153 327.473 399.353 323.161 361.008C323.161 361.008 323.217 361.008 323.217 360.951C318.707 321.046 357.003 289.849 395.185 302.328C395.185 302.299 417.879 309.673 417.908 309.702C458.076 322.805 497.478 287.807 489.166 246.399C489.195 246.399 479.947 200.254 479.947 200.226C477.763 189.42 472.401 179.494 464.572 171.751L381.144 89.5874C354.28 63.126 318.622 47.2719 280.978 45.1447L280.922 45.088Z",
    "M278.624 57.8791C278.624 57.8791 219.251 54.4473 219.223 54.4473C167.254 51.4693 119.313 82.6104 100.96 131.336C100.903 131.336 67.2876 220.533 67.2592 220.561C64.1104 228.956 63.1743 238.117 64.6494 246.966C76.1949 316.395 127.738 372.409 195.962 389.71C195.962 389.795 254.342 404.628 254.398 404.656C288.921 413.42 321.601 384.973 317.601 349.578C317.629 349.578 317.658 349.578 317.658 349.521C313.488 312.68 348.833 283.893 384.094 295.407C384.094 295.379 405.057 302.186 405.086 302.186C442.162 314.296 478.557 281.964 470.869 243.761C470.898 243.761 462.388 201.162 462.359 201.105C460.345 191.15 455.409 181.961 448.147 174.814L371.13 98.9467C346.337 74.5273 313.402 59.8644 278.624 57.8791V57.8791Z",
    "M276.327 70.6418C276.327 70.6418 221.891 67.4936 221.834 67.4936C174.205 64.7709 130.236 93.3027 113.414 137.972C113.357 137.972 82.4935 219.739 82.4651 219.795C79.5717 227.481 78.7206 235.933 80.0539 244.016C80.0539 244.016 80.0539 244.016 80.0539 244.045C90.6349 307.66 137.867 358.994 200.416 374.877C200.416 374.962 253.946 388.604 254.031 388.604C285.66 396.63 315.645 370.566 311.985 338.12C312.042 338.12 312.07 338.12 312.07 338.035C308.241 304.256 340.665 277.88 372.975 288.43C372.975 288.374 392.208 294.613 392.265 294.642C426.249 305.731 459.609 276.121 452.574 241.067C452.63 241.067 444.829 202.013 444.801 201.956C442.957 192.823 438.418 184.4 431.78 177.849L361.174 108.306C338.424 85.9003 308.212 72.4569 276.356 70.6418H276.327Z",
    "M274.057 83.4326C274.057 83.4326 224.528 80.5398 224.499 80.5398C181.182 78.0723 141.213 104.023 125.923 144.609C125.838 144.609 97.7823 218.944 97.754 219.029C95.1158 226.035 94.3215 233.721 95.5413 241.095C95.5413 241.095 95.5413 241.095 95.5413 241.123C105.158 298.952 148.106 345.636 204.954 360.043C204.954 360.157 253.632 372.551 253.746 372.579C282.51 379.868 309.771 356.186 306.452 326.69C306.509 326.69 306.566 326.662 306.537 326.605C303.077 295.918 332.522 271.895 361.882 281.51C361.882 281.453 379.385 287.097 379.441 287.126C410.333 297.222 440.658 270.279 434.275 238.429C434.332 238.429 427.24 202.92 427.24 202.863C425.567 194.553 421.425 186.867 415.411 180.94C415.411 180.94 351.216 117.722 351.216 117.693C330.536 97.3298 303.048 85.0776 274.085 83.4326H274.057Z",
    "M271.761 96.1953C271.761 96.1953 227.167 93.5861 227.11 93.5861C188.134 91.3739 152.107 114.715 138.349 151.245C138.264 151.245 112.989 218.15 112.96 218.264C110.577 224.56 109.868 231.537 110.975 238.145C110.975 238.145 110.975 238.173 110.975 238.202C119.655 290.245 158.291 332.249 209.438 345.238C209.438 345.352 253.265 356.555 253.407 356.583C279.306 363.135 303.872 341.835 300.865 315.289C300.922 315.289 300.979 315.26 300.979 315.175C297.858 287.551 324.354 265.939 350.792 274.59C350.792 274.533 366.564 279.61 366.621 279.61C394.421 288.685 421.739 264.465 415.98 235.791C416.037 235.791 409.682 203.828 409.682 203.742C408.179 196.255 404.463 189.335 399.016 183.974C399.016 183.974 341.26 127.053 341.232 127.053C322.623 108.731 297.858 97.6701 271.789 96.167L271.761 96.1953Z",
    "M269.461 108.958C269.461 108.958 229.804 106.632 229.719 106.604C195.082 104.619 163.027 125.379 150.801 157.853C150.687 157.853 128.192 217.356 128.164 217.441C126.064 223.028 125.384 229.268 126.376 235.167C126.376 235.167 126.376 235.195 126.376 235.224C134.092 281.51 168.417 318.834 213.918 330.377C213.918 330.519 252.923 340.502 253.065 340.53C276.071 346.373 297.942 327.427 295.275 303.831C295.36 303.831 295.417 303.802 295.417 303.717C292.637 279.156 316.21 259.955 339.699 267.641C339.699 267.556 353.712 272.066 353.797 272.094C378.505 280.177 402.788 258.622 397.681 233.153C397.767 233.153 392.121 204.707 392.121 204.65C390.788 198.013 387.469 191.831 382.647 187.066C382.647 187.066 331.302 136.469 331.274 136.469C314.735 120.189 292.666 110.319 269.49 108.986L269.461 108.958Z",
    "M267.192 121.749C267.192 121.749 232.47 119.679 232.385 119.679C202.06 117.949 174.005 136.1 163.311 164.518C163.197 164.518 143.482 216.59 143.454 216.704C141.61 221.61 141.014 227.112 141.865 232.246C141.865 232.246 141.865 232.274 141.865 232.303C148.616 272.803 178.657 305.447 218.457 315.544C218.457 315.714 252.611 324.449 252.781 324.506C272.922 329.611 292.07 313.048 289.744 292.401C289.829 292.401 289.914 292.344 289.886 292.259C287.446 270.761 308.069 253.971 328.635 260.692C328.635 260.607 340.919 264.521 341.004 264.55C362.62 271.612 383.895 252.78 379.413 230.459C379.498 230.459 374.591 205.558 374.562 205.472C373.399 199.658 370.477 194.241 366.251 190.072C366.251 190.072 321.317 145.8 321.288 145.771C306.821 131.534 287.474 122.855 267.192 121.692V121.749Z",
    "M264.895 134.512C264.895 134.512 235.08 132.725 234.995 132.725C209.011 131.25 184.927 146.792 175.736 171.155C175.594 171.155 158.687 215.796 158.631 215.938C157.042 220.135 156.531 224.9 157.269 229.325V229.381C163.056 264.096 188.813 292.089 222.911 300.739C222.939 300.909 252.214 308.453 252.413 308.51C269.66 312.878 286.142 298.697 284.128 280.999C284.213 280.999 284.298 280.943 284.298 280.858C282.227 262.451 299.9 248.043 317.516 253.801C317.516 253.715 328.069 257.062 328.154 257.091C346.677 263.132 364.918 246.994 361.088 227.878C361.173 227.906 357.003 206.522 356.975 206.437C355.982 201.445 353.486 196.765 349.855 193.192C349.855 193.192 311.332 155.216 311.303 155.216C298.907 142.992 282.284 135.533 264.895 134.54V134.512Z",
    "M262.597 147.275C262.597 147.275 237.719 145.743 237.634 145.743C215.99 144.495 195.849 157.485 188.218 177.764C188.076 177.764 173.95 215.002 173.893 215.144C172.588 218.633 172.106 222.688 172.73 226.347C172.73 226.347 172.73 226.404 172.73 226.432C177.552 255.361 199.026 278.674 227.45 285.906C227.479 286.105 251.931 292.429 252.13 292.486C266.512 296.145 280.27 284.318 278.597 269.57C278.682 269.57 278.795 269.513 278.767 269.4C277.036 254.056 291.759 242.059 306.453 246.852C306.453 246.739 315.276 249.518 315.361 249.547C330.792 254.595 346.026 241.152 342.82 225.212C342.934 225.241 339.473 207.401 339.445 207.288C338.622 203.147 336.523 199.205 333.516 196.227C333.516 196.199 301.404 164.575 301.376 164.547C291.05 154.365 277.121 148.069 262.654 147.246L262.597 147.275Z",
    "M260.299 160.066C260.299 160.066 240.357 158.818 240.244 158.818C222.911 157.825 206.77 168.206 200.671 184.428C200.501 184.428 189.154 214.236 189.097 214.378C188.048 217.186 187.651 220.476 188.133 223.426C188.133 223.426 188.133 223.482 188.133 223.511C191.991 246.654 209.153 265.316 231.904 271.073C231.932 271.3 251.534 276.405 251.733 276.433C263.25 279.355 274.285 269.91 272.951 258.112C273.065 258.112 273.15 258.055 273.15 257.942C271.76 245.661 283.532 236.075 295.305 239.904C295.305 239.79 302.368 241.974 302.482 242.002C314.85 246.03 327.019 235.281 324.466 222.546C324.58 222.575 321.828 208.281 321.828 208.167C321.147 204.849 319.474 201.672 317.062 199.29C317.062 199.262 291.362 173.963 291.362 173.935C283.107 165.795 271.902 160.69 260.328 160.037L260.299 160.066Z",
    "M258.03 172.829C258.03 172.829 242.995 171.864 242.882 171.836C229.89 171.098 217.72 178.87 213.124 191.037C212.954 191.037 204.387 213.414 204.331 213.584C203.536 215.683 203.196 218.264 203.565 220.448C203.565 220.448 203.565 220.504 203.565 220.533C206.458 237.89 219.337 251.872 236.386 256.212C236.414 256.438 251.165 260.324 251.392 260.381C260.016 262.565 268.384 255.503 267.363 246.654C267.476 246.682 267.59 246.597 267.59 246.455C266.54 237.238 275.391 230.034 284.185 232.927C284.185 232.813 289.518 234.402 289.631 234.458C298.907 237.493 308.07 229.438 306.141 219.88C306.254 219.909 304.24 209.16 304.212 209.046C303.701 206.55 302.453 204.14 300.638 202.353C300.638 202.325 281.376 183.322 281.348 183.294C275.135 177.196 266.682 173.282 257.973 172.8L258.03 172.829Z",
    "M255.732 185.62C255.732 185.62 245.633 184.91 245.519 184.91C236.867 184.428 228.669 189.59 225.606 197.702C225.407 197.702 219.648 212.677 219.592 212.847C219.053 214.236 218.797 216.08 219.053 217.555C219.053 217.583 219.053 217.611 219.053 217.64C220.982 229.211 229.577 238.542 240.924 241.435C240.952 241.69 250.881 244.356 251.108 244.413C256.866 245.86 262.511 241.18 261.831 235.281C261.944 235.309 262.086 235.196 262.058 235.082C261.377 228.956 267.249 224.135 273.121 226.063C273.121 225.921 276.724 226.971 276.865 226.999C283.049 229.013 289.177 223.652 287.9 217.271C288.042 217.328 286.737 210.096 286.709 209.954C286.368 208.28 285.517 206.607 284.326 205.416C284.326 205.388 271.476 192.71 271.447 192.682C267.306 188.598 261.547 185.903 255.76 185.563L255.732 185.62Z",
    "M253.434 198.382C253.434 198.382 248.271 197.957 248.13 197.928C243.789 197.673 239.591 200.254 238.059 204.31C237.861 204.31 234.882 211.854 234.797 212.053C234.542 212.762 234.343 213.868 234.457 214.605C234.457 214.633 234.457 214.662 234.457 214.719C235.421 220.504 239.704 225.156 245.406 226.602C245.463 226.886 250.512 228.332 250.768 228.389C253.633 229.126 256.583 226.801 256.243 223.851C256.384 223.879 256.498 223.766 256.498 223.624C256.157 220.561 259.108 218.15 262.03 219.115C262.03 218.973 263.902 219.427 264.044 219.483C267.136 220.504 270.256 217.838 269.604 214.633C269.745 214.69 269.178 211.003 269.15 210.861C268.98 210.039 268.526 209.103 267.93 208.507C267.93 208.479 261.491 202.126 261.462 202.098C259.391 200.056 256.356 198.552 253.463 198.382H253.434Z",
    "M250.768 210.975H250.513C250.286 210.975 250.087 211.088 250.031 211.287L249.889 211.655C249.889 211.655 249.889 211.74 249.889 211.769C249.945 212.052 250.144 212.279 250.428 212.364L250.683 212.421C250.825 212.449 250.967 212.336 250.938 212.194C250.938 212.052 251.08 211.911 251.222 211.967H251.307C251.449 212.052 251.619 211.911 251.591 211.74V211.57C251.591 211.57 251.534 211.485 251.506 211.457L251.194 211.145C251.194 211.145 250.967 210.975 250.796 210.975H250.768Z",
  ],
  global: [
    "M110.3 101.018L11.3546 273.979C-3.96371 300.74 -0.190851 334.413 20.6308 357.09L107.406 451.482C128.086 473.987 160.85 480.47 188.452 467.504L246.69 440.143C277.071 425.863 313.211 435.259 332.927 462.534C360.159 500.205 407.221 510.43 436.042 473.959L445.885 454.852C454.083 400.958 471.756 369.971 510.222 348.265C540.859 330.986 569.17 309.823 593.395 284.204L597.338 280.034C612.061 261.413 603.608 249.046 589.197 235.023C574.077 220.343 556.887 207.977 538.334 198.009L501.06 177.96C498.053 176.332 495.358 174.133 493.23 171.448L493.174 171.362C491.33 169.049 489.911 166.45 488.947 163.651L459.53 78.57C414.568 12.8814 331.537 -14.3937 256.76 11.9389L187.885 36.2151C155.149 47.7535 127.604 70.7445 110.272 101.018H110.3Z",
    "M448.892 90.4511C406.738 28.8751 328.898 3.28511 258.803 27.9898L194.21 50.7523C163.517 61.5767 137.703 83.1397 121.448 111.5L28.6871 273.637C14.3332 298.713 17.8508 330.3 37.3958 351.549L118.725 440.057C138.128 461.164 168.822 467.218 194.693 455.08L249.3 429.433C277.781 416.067 311.651 424.863 330.147 450.425C355.677 485.725 399.817 495.322 426.822 461.135L436.07 443.228C443.758 392.705 460.324 363.659 496.408 343.295C525.144 327.102 551.667 307.252 574.389 283.233L578.077 279.32C591.864 261.87 583.949 250.274 570.446 237.137C556.291 223.371 540.15 211.804 522.761 202.436L487.812 183.643C484.975 182.13 482.451 180.073 480.465 177.56L480.408 177.474C478.678 175.304 477.345 172.876 476.437 170.249L448.864 90.4797L448.892 90.4511Z",
    "M438.226 102.304C398.881 44.8403 326.232 20.9639 260.789 44.0121L200.508 65.2323C171.857 75.3141 147.745 95.4491 132.597 121.925L46.0195 273.265C32.6301 296.685 35.9207 326.159 54.1326 345.98L130.044 428.576C148.142 448.254 176.793 453.938 200.962 442.571L251.91 418.637C278.49 406.156 310.119 414.353 327.367 438.23C351.195 471.188 392.385 480.128 417.603 448.226L426.227 431.518C433.404 384.365 448.864 357.233 482.536 338.269C509.343 323.16 534.136 304.625 555.298 282.205L558.759 278.549C571.638 262.27 564.262 251.417 551.639 239.164C538.42 226.312 523.356 215.517 507.159 206.777L474.536 189.213C471.898 187.785 469.544 185.871 467.671 183.529L467.615 183.443C465.998 181.416 464.75 179.131 463.899 176.703L438.169 102.246L438.226 102.304Z",
    "M427.56 114.156C391.051 60.7769 323.565 38.6142 262.803 60.0344L206.834 79.741C180.225 89.1087 157.843 107.787 143.773 132.406L63.3803 272.923C50.9554 294.657 53.9907 322.018 70.926 340.439L141.419 417.152C158.212 435.431 184.849 440.686 207.259 430.147L254.576 407.927C279.256 396.332 308.616 403.957 324.643 426.12C346.77 456.708 385.009 465.019 408.44 435.402L416.44 419.865C423.106 376.082 437.46 350.892 468.721 333.271C493.627 319.219 516.633 302.026 536.292 281.234L539.497 277.864C551.44 262.727 544.604 252.673 532.888 241.306C520.605 229.368 506.62 219.344 491.585 211.233L461.289 194.925C458.849 193.611 456.665 191.812 454.906 189.641L454.849 189.584C453.346 187.699 452.183 185.585 451.417 183.329L427.532 114.185L427.56 114.156Z",
    "M416.894 126.037C383.194 76.7707 320.899 56.3215 264.817 76.0567L213.16 94.2496C188.594 102.903 167.942 120.154 154.95 142.859L80.7412 272.58C69.2524 292.658 72.0891 317.905 87.7195 334.899L152.794 405.699C168.311 422.579 192.877 427.434 213.585 417.695L257.271 397.16C280.05 386.45 307.169 393.504 321.949 413.953C342.373 442.199 377.69 449.854 399.278 422.521L406.682 408.184C412.837 367.771 426.085 344.523 454.935 328.244C477.912 315.278 499.159 299.398 517.314 280.206L520.264 277.092C531.299 263.127 524.973 253.844 514.165 243.334C502.819 232.31 489.94 223.056 476.04 215.574L448.07 200.522C445.8 199.294 443.786 197.666 442.198 195.639L442.141 195.581C440.751 193.839 439.701 191.897 438.964 189.784L416.894 125.98V126.037Z",
    "M406.228 137.89C375.336 92.7359 318.232 73.9718 266.831 92.079L219.486 108.758C196.962 116.698 178.041 132.492 166.127 153.312L98.1019 272.209C87.5776 290.602 90.1591 313.764 104.485 329.358L164.141 394.247C178.353 409.726 200.877 414.182 219.854 405.243L259.881 386.421C280.759 376.625 305.609 383.051 319.169 401.815C337.891 427.719 370.258 434.745 390.058 409.669L396.838 396.532C402.483 359.489 414.625 338.183 441.063 323.246C462.14 311.365 481.6 296.828 498.251 279.206L500.975 276.35C511.102 263.555 505.286 255.044 495.386 245.419C485.004 235.309 473.175 226.826 460.438 219.972L434.822 206.177C432.751 205.063 430.907 203.55 429.432 201.693L429.376 201.636C428.127 200.037 427.135 198.266 426.454 196.324L406.228 137.833V137.89Z",
    "M395.562 149.771C367.478 108.73 315.566 91.6506 268.816 108.13L225.755 123.295C205.274 130.493 188.083 144.887 177.247 163.794L115.406 271.895C105.846 288.631 108.201 309.68 121.221 323.846L175.431 382.851C188.367 396.903 208.82 400.958 226.095 392.847L262.491 375.74C281.468 366.829 304.077 372.684 316.388 389.734C333.409 413.268 362.826 419.665 380.839 396.874L386.995 384.936C392.129 351.264 403.164 331.871 427.22 318.334C446.368 307.538 464.069 294.314 479.217 278.292L481.685 275.693C490.876 264.069 485.6 256.329 476.607 247.561C467.161 238.365 456.41 230.654 444.836 224.427L421.546 211.889C419.674 210.861 417.972 209.49 416.639 207.834L416.582 207.777C415.447 206.32 414.539 204.692 413.944 202.95L395.562 149.771V149.771Z",
    "M384.896 161.623C359.62 124.666 312.899 109.329 270.831 124.152L232.081 137.804C213.67 144.287 198.153 157.225 188.423 174.247L132.767 271.523C124.143 286.575 126.271 305.539 137.986 318.276L186.778 371.37C198.409 384.022 216.848 387.678 232.364 380.367L265.129 364.973C282.206 356.947 302.545 362.231 313.637 377.568C328.955 398.759 355.422 404.5 371.648 383.994L377.18 373.255C381.804 342.953 391.732 325.502 413.376 313.307C430.624 303.596 446.538 291.687 460.154 277.264L462.395 274.922C470.678 264.44 465.941 257.472 457.828 249.618C449.318 241.364 439.644 234.395 429.234 228.797L408.27 217.516C406.568 216.602 405.065 215.374 403.845 213.86L403.788 213.803C402.767 212.489 401.944 211.033 401.405 209.462L384.867 161.595L384.896 161.623Z",
    "M374.229 173.504C351.763 140.66 310.233 127.008 272.845 140.203L238.407 152.341C222.039 158.11 208.252 169.592 199.6 184.729L150.128 271.209C142.468 284.604 144.341 301.426 154.78 312.764L198.153 359.974C208.508 371.227 224.875 374.455 238.662 367.971L267.795 354.291C282.972 347.151 301.07 351.863 310.914 365.487C324.53 384.308 348.075 389.42 362.485 371.199L367.421 361.631C371.535 334.699 380.357 319.19 399.59 308.337C414.908 299.684 429.064 289.116 441.176 276.321L443.162 274.237C450.509 264.926 446.311 258.728 439.106 251.731C431.56 244.391 422.936 238.222 413.688 233.224L395.051 223.171C393.548 222.371 392.186 221.257 391.136 219.915V219.858C390.172 218.687 389.463 217.402 388.98 216.002L374.286 173.447L374.229 173.504Z",
    "M363.563 185.357C343.905 156.625 307.566 144.687 274.83 156.197L244.704 166.821C230.379 171.876 218.323 181.93 210.749 195.182L167.46 270.838C160.765 282.548 162.411 297.285 171.517 307.195L209.472 348.493C218.521 358.346 232.847 361.174 244.931 355.491L270.405 343.524C283.681 337.269 299.51 341.382 308.134 353.32C320.048 369.799 340.643 374.283 353.238 358.318L357.55 349.95C361.124 326.388 368.868 312.821 385.69 303.311C399.108 295.742 411.476 286.489 422.085 275.293L423.816 273.465C430.255 265.326 426.567 259.899 420.27 253.787C413.66 247.361 406.143 241.963 398.03 237.594L381.718 228.797C380.414 228.083 379.222 227.112 378.286 225.941V225.884C377.435 224.884 376.811 223.742 376.385 222.514L363.507 185.3L363.563 185.357Z",
    "M352.897 197.209C336.047 172.59 304.9 162.337 276.844 172.219L251.002 181.33C238.719 185.642 228.393 194.268 221.897 205.635L184.792 270.495C179.062 280.548 180.452 293.172 188.282 301.683L220.819 337.098C228.563 345.523 240.846 347.951 251.2 343.095L273.043 332.842C284.418 327.501 297.978 331.014 305.382 341.239C315.594 355.376 333.239 359.203 344.047 345.523L347.734 338.354C350.798 318.134 357.436 306.51 371.875 298.399C383.364 291.915 393.973 283.976 403.079 274.379L404.583 272.837C410.114 265.868 406.937 261.213 401.547 255.958C395.874 250.446 389.434 245.819 382.484 242.078L368.499 234.538C367.365 233.938 366.372 233.11 365.549 232.082H365.521C364.84 231.168 364.301 230.197 363.932 229.14L352.897 197.238V197.209Z",
    "M342.231 209.09C328.189 188.556 302.233 180.045 278.859 188.27L257.328 195.867C247.087 199.466 238.492 206.663 233.074 216.116L202.153 270.152C197.359 278.521 198.551 289.059 205.047 296.142L232.138 325.645C238.605 332.671 248.846 334.699 257.47 330.643L275.681 322.104C285.184 317.648 296.475 320.59 302.63 329.101C311.141 340.868 325.863 344.066 334.856 332.671L337.948 326.702C340.501 309.851 346.032 300.169 358.06 293.4C367.648 288.003 376.499 281.405 384.045 273.38L385.293 272.094C389.888 266.268 387.25 262.412 382.768 258.043C378.059 253.445 372.669 249.589 366.882 246.476L355.252 240.193C354.316 239.679 353.465 238.993 352.812 238.165H352.784C352.216 237.394 351.763 236.594 351.451 235.709L342.26 209.119L342.231 209.09Z",
    "M331.565 220.943C320.332 204.521 299.567 197.695 280.873 204.292L263.654 210.347C255.455 213.232 248.562 218.972 244.25 226.541L219.514 269.781C215.684 276.464 216.621 284.889 221.84 290.573L243.513 314.192C248.676 319.819 256.874 321.447 263.767 318.191L278.32 311.365C285.922 307.795 294.943 310.137 299.879 316.963C306.687 326.388 318.459 328.929 325.665 319.819L328.133 315.049C330.175 301.569 334.6 293.829 344.217 288.402C351.876 284.09 358.968 278.806 365.01 272.409L366.031 271.38C369.719 266.725 367.62 263.641 364.017 260.128C360.244 256.443 355.932 253.359 351.309 250.874L342.004 245.848C341.238 245.448 340.586 244.876 340.047 244.22C339.593 243.62 339.224 242.963 338.969 242.249L331.622 220.972L331.565 220.943Z",
    "M320.899 232.824C312.474 220.515 296.9 215.402 282.858 220.343L269.951 224.884C263.795 227.055 258.633 231.367 255.399 237.022L236.847 269.467C233.981 274.493 234.691 280.805 238.577 285.061L254.831 302.768C258.718 306.995 264.845 308.195 270.036 305.767L280.958 300.655C286.66 297.97 293.439 299.741 297.127 304.853C302.233 311.907 311.055 313.821 316.474 306.995L318.317 303.425C319.849 293.315 323.168 287.517 330.374 283.433C336.132 280.206 341.437 276.236 345.976 271.438L346.742 270.666C349.493 267.182 347.933 264.84 345.238 262.213C342.401 259.442 339.196 257.129 335.707 255.272L328.728 251.502C328.161 251.188 327.65 250.788 327.253 250.274C326.913 249.817 326.629 249.332 326.459 248.789L320.927 232.853L320.899 232.824Z",
    "M310.233 244.677C304.616 236.48 294.234 233.053 284.872 236.337L276.249 239.364C272.164 240.792 268.703 243.677 266.547 247.476L254.179 269.096C252.278 272.437 252.732 276.664 255.342 279.492L266.178 291.287C268.76 294.086 272.845 294.914 276.305 293.286L283.596 289.859C287.397 288.088 291.907 289.259 294.375 292.658C297.78 297.37 303.652 298.656 307.254 294.086L308.474 291.687C309.495 284.947 311.708 281.091 316.53 278.378C320.36 276.207 323.906 273.58 326.941 270.381L327.452 269.867C329.296 267.553 328.246 265.983 326.431 264.24C324.558 262.413 322.402 260.87 320.076 259.614L315.424 257.072C315.055 256.872 314.715 256.586 314.431 256.244C314.204 255.929 314.006 255.587 313.892 255.244L310.204 244.62L310.233 244.677Z",
    "M299.567 256.558C296.758 252.445 291.567 250.76 286.886 252.388L282.575 253.902C280.532 254.616 278.802 256.044 277.695 257.957L271.511 268.782C270.547 270.467 270.774 272.58 272.079 273.98L277.497 279.892C278.802 281.291 280.844 281.691 282.575 280.891L286.206 279.178C288.106 278.292 290.376 278.863 291.595 280.577C293.297 282.947 296.248 283.576 298.035 281.291L298.659 280.091C299.169 276.721 300.276 274.779 302.687 273.437C304.616 272.352 306.375 271.038 307.878 269.438C307.878 269.438 308.134 269.181 308.162 269.181C309.07 268.01 308.559 267.239 307.651 266.354C306.715 265.44 305.637 264.669 304.474 264.041L302.148 262.755C301.95 262.641 301.779 262.498 301.666 262.327C301.552 262.156 301.467 261.984 301.382 261.813L299.538 256.501L299.567 256.558Z",
    "M288.93 268.467V268.41H288.844V268.467C288.844 268.467 288.844 268.496 288.872 268.467H288.93Z",
  ],
  gender: [
    "M163.722 9.73596L114.335 33.6695C88.1517 46.3502 71.3015 72.8256 70.7909 102.071L69.486 173.786C69.1172 194.949 60.1248 215.056 44.6646 229.393L26.8499 245.901C-13.6019 283.372 -3.07763 350.232 46.8772 373.308L144.574 418.434C151.666 421.718 159.183 423.86 166.928 424.86L326.295 445.309C352.081 448.622 377.81 438.597 394.632 418.662L508.641 283.686C547.334 237.875 521.69 167.046 462.742 157.107L336.933 135.858C303.545 130.232 277.759 103.299 273.362 69.5126C266.611 17.1616 210.954 -13.1694 163.722 9.73596Z",
    "M269.93 78.652C263.547 29.5854 211.379 1.16795 167.098 22.6453L120.802 45.0937C96.2648 57.0033 80.4642 81.7936 79.9819 109.212L78.7621 176.442C78.3933 196.292 69.9966 215.113 55.5009 228.565L38.8209 244.045C0.893823 279.174 10.7657 341.835 57.6001 363.484L149.198 405.781C155.836 408.837 162.9 410.865 170.133 411.808L319.544 431C343.713 434.113 367.825 424.717 383.626 406.01L490.485 279.488C526.767 236.533 502.712 170.131 447.452 160.82L329.501 140.913C298.212 135.63 274.014 110.382 269.901 78.7091L269.93 78.652Z",
    "M266.44 87.8198C260.483 42.0377 211.805 15.5052 170.474 35.5545L127.27 56.5178C104.378 67.6277 89.6268 90.7901 89.1729 116.38L88.0382 179.127C87.6978 197.634 79.8684 215.227 66.3089 227.765L50.7352 242.188C15.3328 274.975 24.5522 333.495 68.2662 353.659L153.765 393.129C159.978 395.985 166.559 397.87 173.31 398.756L312.736 416.663C335.316 419.548 357.811 410.78 372.534 393.329L472.274 275.232C506.144 235.134 483.677 173.187 432.106 164.476L322.012 145.883C292.794 140.942 270.242 117.38 266.384 87.8198H266.44Z",
    "M262.951 96.9877C257.42 54.4614 212.23 29.8424 173.849 48.4637L133.738 67.9133C112.462 78.2236 98.7894 99.7295 98.3639 123.492L97.3143 181.755C97.0023 198.948 89.7119 215.284 77.1451 226.908L62.6778 240.303C29.8284 270.748 38.367 325.07 78.9607 343.834L158.361 380.477C164.119 383.133 170.247 384.875 176.516 385.675L305.984 402.297C326.948 404.982 347.826 396.842 361.528 380.648L454.119 270.977C485.55 233.734 464.7 176.214 416.815 168.131L314.58 150.881C287.46 146.311 266.497 124.434 262.951 96.9877V96.9877Z",
    "M259.462 106.156C254.356 66.9137 212.628 44.1798 177.197 61.3445L140.149 79.3089C120.519 88.848 107.895 108.669 107.498 130.603L106.534 184.382C106.25 200.262 99.527 215.313 87.9248 226.08L74.5638 238.447C44.2391 266.55 52.1252 316.702 89.5984 334.01L162.871 367.853C168.176 370.31 173.849 371.909 179.636 372.652L299.148 387.988C318.494 390.473 337.784 382.962 350.408 367.996L435.878 266.779C464.898 232.421 445.637 179.298 401.44 171.844L307.062 155.936C282.014 151.709 262.668 131.517 259.405 106.184L259.462 106.156Z",
    "M255.973 115.323C251.292 79.3374 213.053 58.517 180.572 74.2537L146.617 90.7044C128.632 99.4439 117.058 117.637 116.689 137.743L115.81 187.038C115.554 201.575 109.399 215.399 98.761 225.252L86.5064 236.59C58.7064 262.352 65.94 308.305 100.293 324.185L167.467 355.201C172.346 357.429 177.509 358.914 182.813 359.599L292.368 373.651C310.098 375.936 327.77 369.024 339.344 355.315L417.695 262.523C444.303 231.021 426.659 182.326 386.122 175.5L299.602 160.906C276.653 157.05 258.923 138.543 255.916 115.323H255.973Z",
    "M252.484 124.491H252.512C248.257 91.7897 213.507 72.8542 183.977 87.1629L153.113 102.129C136.745 110.068 126.249 126.605 125.909 144.883L125.114 189.694C124.887 202.918 119.271 215.456 109.597 224.424L98.4774 234.705C73.2021 258.125 79.7833 299.908 111.016 314.331L172.091 342.52C176.516 344.548 181.225 345.891 186.047 346.519L285.617 359.285C301.729 361.342 317.814 355.087 328.338 342.635L399.54 258.268C423.737 229.622 407.681 185.382 370.832 179.156L292.169 165.904C271.291 162.391 255.178 145.569 252.455 124.463L252.484 124.491Z",
    "M248.994 133.659H249.023C245.222 104.242 213.904 87.1915 187.352 100.072L159.581 113.553C144.858 120.693 135.412 135.601 135.128 152.052L134.419 192.379C134.22 204.289 129.171 215.57 120.462 223.624L110.448 232.877C87.6978 253.955 93.6266 291.569 121.71 304.535L176.686 329.897C180.686 331.725 184.913 332.924 189.253 333.467L278.865 344.977C293.361 346.833 307.828 341.207 317.303 329.982L381.385 254.069C403.142 228.308 388.703 188.466 355.542 182.868L284.737 170.959C265.958 167.789 251.434 152.68 248.994 133.659Z",
    "M245.505 142.827H245.534C242.158 116.666 214.33 101.529 190.7 112.982L166.02 124.948C152.943 131.317 144.518 144.541 144.291 159.163L143.667 195.007C143.496 205.574 139.014 215.627 131.27 222.796L122.363 231.021C102.137 249.757 107.385 283.172 132.376 294.711L181.225 317.273C184.771 318.901 188.544 319.958 192.402 320.443L272.057 330.668C284.964 332.324 297.815 327.298 306.211 317.33L363.173 249.842C382.519 226.937 369.669 191.522 340.195 186.553L277.248 175.957C260.54 173.158 247.661 159.706 245.477 142.827H245.505Z",
    "M242.044 152.023H242.101C239.151 129.147 214.812 115.895 194.132 125.919L172.516 136.401C161.056 141.97 153.709 153.566 153.51 166.332L152.971 197.691C152.801 206.945 148.886 215.713 142.135 221.996L134.334 229.193C116.632 245.587 121.228 274.833 143.099 284.914L185.849 304.65C188.969 306.078 192.26 306.992 195.607 307.42L265.306 316.359C276.596 317.816 287.829 313.418 295.205 304.707L345.018 245.644C361.953 225.595 350.691 194.607 324.905 190.266L269.816 181.012C255.207 178.556 243.917 166.818 242.016 152.023H242.044Z",
    "M238.555 161.191H238.612C236.087 141.57 215.209 130.232 197.508 138.829L178.984 147.796C169.169 152.566 162.871 162.505 162.701 173.472L162.247 200.347C162.105 208.287 158.758 215.798 152.943 221.168L146.276 227.337C131.1 241.389 135.043 266.436 153.794 275.09L190.444 291.997C193.111 293.197 195.948 293.997 198.813 294.368L258.526 302.022C268.199 303.25 277.844 299.509 284.142 292.026L326.834 241.417C341.33 224.224 331.714 197.691 309.587 193.95L262.355 186.039C249.817 183.954 240.172 173.872 238.527 161.22L238.555 161.191Z",
    "M235.066 170.359H235.123C233.024 154.023 215.634 144.541 200.883 151.738L185.452 159.221C177.282 163.219 172.034 171.502 171.892 180.612L171.523 203.003C171.41 209.601 168.63 215.884 163.779 220.34L158.219 225.452C145.567 237.162 148.858 258.039 164.488 265.236L195.04 279.317C197.281 280.316 199.607 280.973 202.018 281.259L251.774 287.628C259.831 288.656 267.859 285.514 273.135 279.288L308.679 237.104C320.764 222.796 312.736 200.662 294.297 197.548L254.923 190.98C244.484 189.237 236.428 180.841 235.066 170.302V170.359Z",
    "M231.577 179.527H231.634C229.96 166.446 216.06 158.878 204.231 164.618L191.891 170.616C185.338 173.815 181.168 180.441 181.055 187.752L180.743 205.66C180.658 210.943 178.417 215.941 174.559 219.511L170.105 223.595C160.006 232.963 162.616 249.671 175.126 255.412L199.55 266.693C201.337 267.493 203.21 268.007 205.139 268.235L244.938 273.319C251.377 274.147 257.817 271.634 262.015 266.664L290.439 232.906C300.112 221.453 293.673 203.746 278.922 201.261L247.406 196.006C239.038 194.607 232.598 187.924 231.549 179.47L231.577 179.527Z",
    "M228.088 188.695H228.144C226.896 178.899 216.457 173.215 207.606 177.528L198.359 182.012C193.451 184.411 190.331 189.409 190.246 194.864L190.019 208.287C189.962 212.257 188.288 215.998 185.395 218.683L182.047 221.739C174.473 228.765 176.431 241.274 185.82 245.587L204.146 254.041C205.507 254.64 206.897 255.012 208.316 255.183L238.158 258.982C242.981 259.61 247.803 257.725 250.952 253.984L272.256 228.679C279.489 220.083 274.667 206.802 263.604 204.946L239.945 201.033C233.676 200.005 228.854 194.978 228.059 188.666L228.088 188.695Z",
    "M224.599 197.863H224.655C223.833 191.322 216.883 187.552 210.982 190.437L204.798 193.436C201.536 195.064 199.465 198.377 199.408 202.032L199.267 210.972C199.238 213.599 198.132 216.113 196.175 217.883L193.962 219.911C188.913 224.595 190.217 232.935 196.487 235.819L208.713 241.446C209.621 241.845 210.557 242.074 211.493 242.188L231.35 244.73C234.584 245.13 237.789 243.873 239.889 241.389L254.044 224.509C258.866 218.769 255.632 209.944 248.257 208.687L232.456 206.117C228.258 205.431 225.052 202.118 224.542 197.891L224.599 197.863Z",
    "M221.109 207.03H221.194C220.797 203.775 217.336 201.89 214.386 203.346L211.294 204.831C209.649 205.66 208.656 207.316 208.628 209.144L208.571 213.599C208.571 214.913 208.004 216.141 207.039 217.027L205.933 218.026C203.408 220.368 204.061 224.509 207.209 225.966L213.337 228.765C213.791 228.936 214.273 229.05 214.727 229.079L224.627 230.336C226.244 230.536 227.832 229.907 228.882 228.679L235.917 220.225C238.328 217.369 236.711 212.942 232.995 212.343L225.052 211.086C222.953 210.772 221.336 209.115 221.109 207.002V207.03Z",
    "M217.819 216.283H217.847C217.847 216.283 217.847 216.255 217.847 216.226V216.169C217.847 216.169 217.847 216.169 217.847 216.141V216.055H217.762L217.649 215.998C217.649 215.998 217.62 215.998 217.592 215.998L217.507 216.084C217.507 216.084 217.507 216.169 217.535 216.169H217.62C217.62 216.169 217.649 216.198 217.649 216.226C217.649 216.226 217.677 216.283 217.705 216.255L217.819 216.283Z",
  ],
};

// generate frames when component loads
const frames = {};
const entries = Object.entries(paths);
const steps = 10;
entries.forEach(([key, values]) => {
  const t = values?.map((value, i) =>
    toCircle(
      value,
      543 / 2,
      469 / 2,
      // eslint-disable-next-line no-unsafe-optional-chaining
      i === values?.length - 1 ? 5 : 334 - i * 20
    )
  );
  frames[key] = t?.map((interpolater, i) => {
    return [...Array(steps)].map((e, _i) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const frame = Math.max(_i === t?.length - 1 ? 1 : _i / steps, 0);
      return interpolater(frame);
    });
  });
});

const Circle = ({
  i,
  className: _className,
  hovered,
  fade,
  scrollYProgress,
}) => {
  const colors = ["light-viridian", "light-green", "coral"];
  const color = colors[i];
  const names = ["philanthropy", "global", "gender"];
  const name = names[i];
  const defaults = {
    transition: {
      type: "spring",
      duration: 0.15,
      restDelta: 1,
      bounce: 0.2,
      damping: 12,
      mass: 0.5,
    },
  };
  const variants = {
    default: custom => ({
      ...defaults,
      d: frames[name][custom][0],
    }),
    reverse: custom => ({
      ...defaults,
      d: [...frames[name][custom]]?.reverse(),
    }),
    hover: custom => ({
      ...defaults,
      d: frames[name][custom],
    }),
    tap: custom => ({
      scale: 0.975,
      d: frames[name][custom][steps - 1],
    }),
  };

  // const scale = useTransform(
  //   scrollYProgress,
  //   [0, 0.1, 0.3, 0.8, 1],
  //   [0, 0, 1, 1, 0]
  // );
  // const scaleSpring = useSpring(scale, {
  //   stiffness: 100,
  //   damping: 30,
  //   restDelta: 0.001,
  // });
  // const opacity = useTransform(
  //   scrollYProgress,
  //   [0, 0.4, 0.6, 0.8, 0.85],
  //   [0, 0, 1, 1, 0]
  // );

  return (
    <m.div initial="default" animate={hovered ? "hover" : "default"}>
      <div
        className={classNames(
          "w-full text-white transition duration-200 group-hover:scale-125",
          {
            "group-hover:text-coral": color === "coral",
            "group-hover:text-viridian-light": color === "light-viridian",
            "group-hover:text-light-green": color === "light-green",
            "opacity-100": !fade,
            "opacity-40": fade,
          }
        )}
      >
        <m.svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 543 469"
          className="overflow-visible stroke-current stroke-2"
          animate={{
            x: [-10, 0, 10, 0],
            y: [0, 10, 0, -10],
          }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            ease: "backInOut",
            duration: 15,
          }}
        >
          {paths?.[name]?.map((thing, ii) => {
            return (
              <m.path
                // eslint-disable-next-line react/no-array-index-key
                key={ii}
                variants={variants}
                custom={ii}
              />
            );
          })}
        </m.svg>
      </div>
    </m.div>
  );
};

Circle.defaultProps = {};

export default Circle;
